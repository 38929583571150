import React from "react"


const IslandSvg = ({className}) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 122">
        <path className="cls-4" d="M86.09,110.87A53.55,53.55,0,0,1,53.82,122c-19.26,0-30.45-11.13-30.45-11.13S47.09,103.63,86.09,110.87Z"/>
        <path className="cls-2" d="M106,120.72a2,2,0,0,1-1.15-.36c-9.43-6.61-29.46-10.87-51-10.87-21.25,0-41.16,4.18-50.71,10.65a2,2,0,1,1-2.24-3.31c10.33-7,30.61-11.34,53-11.34,22.67,0,43.1,4.44,53.32,11.6a2,2,0,0,1-1.15,3.64Z"/>
        <path className="cls-2" d="M49.28,115h-.1a2,2,0,0,1-1.9-2.09c0-.38,1.6-38-13.23-57.39a2,2,0,1,1,3.18-2.43c6.63,8.66,11,21.4,13,37.89a145.17,145.17,0,0,1,1,22.12A2,2,0,0,1,49.28,115Z"/>
        <path className="cls-2" d="M60.19,115a2,2,0,0,1-2-1.95c-.08-2.92-1.65-71.63,15.05-90.35a2,2,0,1,1,3,2.66c-15.66,17.55-14.06,86.89-14,87.59A2,2,0,0,1,60.24,115Z"/>
        <path className="cls-3" d="M22,78.3a2,2,0,0,1-.37,0A2,2,0,0,1,20,75.93c.14-.72,3.47-17.66,17.15-20.75a2,2,0,0,1,.88,3.9C27,61.59,24,76.52,24,76.67A2,2,0,0,1,22,78.3Z"/>
        <path className="cls-3" d="M9.28,61.78a2,2,0,0,1-1.42-3.41c.14-.14,14.07-13.87,30.7-3a2,2,0,0,1-2.19,3.35c-13.87-9.05-25.2,2-25.67,2.49A2,2,0,0,1,9.28,61.78Z"/>
        <path className="cls-3" d="M37.73,58.78a2,2,0,0,1-2-1.7C34.13,46.25,40,33.77,40.2,33.25A2,2,0,0,1,43.82,35c-.06.12-5.55,11.92-4.11,21.52A2,2,0,0,1,38,58.76Z"/>
        <path className="cls-3" d="M37.46,59a2,2,0,0,1-1.82-1.17c-5-11-15.94-14.48-16-14.51a2,2,0,1,1,1.19-3.82c.52.16,12.74,4.08,18.5,16.67A2,2,0,0,1,37.46,59Z"/>
        <path className="cls-3" d="M37.92,59.08a2,2,0,0,1-1.79-2.89A21.57,21.57,0,0,1,48.77,45a2,2,0,0,1,1,3.86A17.55,17.55,0,0,0,39.71,58,2,2,0,0,1,37.92,59.08Z"/>
        <path className="cls-3" d="M74.44,25.75A2,2,0,0,1,72.9,25c-8.42-10.11-29.18-3.76-29.39-3.69a2,2,0,0,1-1.2-3.82c1-.3,23.53-7.21,33.66,4.95a2,2,0,0,1-1.54,3.28Z"/>
        <path className="cls-3" d="M74.74,25.63a2,2,0,0,1-1.94-1.5C69.42,11,56.63,3.83,56.5,3.75A2,2,0,1,1,58.43.25C59,.57,72.87,8.32,76.68,23.13a2,2,0,0,1-1.44,2.44A2,2,0,0,1,74.74,25.63Z"/>
        <path className="cls-3" d="M92,48a2,2,0,0,1-2-1.69h0c0-.17-2.84-16.95-15.81-20.39a2,2,0,0,1,1-3.87C81.72,23.8,87,28.46,90.58,35.56A38.21,38.21,0,0,1,94,45.72,2,2,0,0,1,92.32,48Z"/>
        <path className="cls-3" d="M74.74,26a2,2,0,0,1-1.59-3.21C79.09,15,93,20.09,93.62,20.31a2,2,0,0,1-1.4,3.75C89,22.85,79.77,20.71,76.33,25.23A2,2,0,0,1,74.74,26Z"/>
        <path className="cls-3" d="M74.74,43.44a2,2,0,0,1-.5-.06,2,2,0,0,1-1.44-2.44c.78-3,2.34-11.83.16-16a2,2,0,1,1,3.54-1.85c3.28,6.28.49,17.58.16,18.85A2,2,0,0,1,74.74,43.44Z"/>
        <path className="cls-1" d="M82.92,85.64a9.34,9.34,0,1,1,9.27-9.34A9.32,9.32,0,0,1,82.92,85.64Zm0-14.68a5.34,5.34,0,1,0,5.27,5.34A5.31,5.31,0,0,0,82.92,71Z"/>
    </svg>
)

const MontainSvg = ({className}) => (
    <svg  className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124 69.6">
        <polygon className="cls-3" points="47.3,28.5 24.4,67.6 34.4,67.6 52.3,36.7 "/>
        <path className="cls-1" d="M83.5,37c-0.4,0-0.9-0.1-1.2-0.4l-7.6-5.9l-5.8,5.7c-0.8,0.8-2,0.8-2.8,0l-8.4-8.2c-0.8-0.7-0.3-1.3,0.4-2.2
            c0.7-0.8,1.4-1.6,2.2-0.8c0,0,0.1,0.1,0.1,0.1l7,6.8l5.6-5.6c0.7-0.7,1.8-0.8,2.6-0.2l7.6,5.9l5.3-5.5c0.8-0.8,1.2-0.4,2,0.4
            s1.7,1.6,0.9,2.4L85,36.4C84.6,36.8,84.1,37.1,83.5,37z"/>
        <path className="cls-2" d="M13.4,69.6c-1.1,0-2-0.9-2-2c0-0.3,0.1-0.7,0.3-1l29-47.8c0.4-0.6,1-1,1.7-1c0.7,0,1.4,0.4,1.7,1l10,16.9
            c0.6,0.9,0.5,2.1-0.4,2.8s-2.1,0.5-2.8-0.4c-0.1-0.1-0.1-0.2-0.2-0.4l-8.3-14l-27.2,45C14.7,69.2,14,69.6,13.4,69.6z"/>
        <path className="cls-2" d="M34.4,69.6c-1.1,0-2-0.9-2-2c0-0.3,0.1-0.7,0.3-1L72.4,1c0.6-0.9,1.8-1.2,2.7-0.7c0.3,0.2,0.5,0.4,0.7,0.7
            l39.7,65.5c0.5,1,0.2,2.2-0.8,2.7c-0.9,0.5-2.1,0.2-2.7-0.7l-38-62.7l-38,62.7C35.8,69.2,35.1,69.6,34.4,69.6z"/>
        <path className="cls-2" d="M52.3,69.6H2c-1.1,0-2-0.9-2-2s0.9-2,2-2h50.3c1.1,0,2,0.9,2,2S53.4,69.6,52.3,69.6z"/>
        <path className="cls-2" d="M122,69.6H62.4c-1.1,0-2-0.9-2-2s0.9-2,2-2H122c1.1,0,2,0.9,2,2S123.1,69.6,122,69.6z"/>
    </svg>
)

const HotelSvg = ({className}) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94 124">
        <path className="cls-2" d="M27.38,124H14a2,2,0,0,1-2-2V98.69a8.69,8.69,0,0,1,17.38,0V122A2,2,0,0,1,27.38,124ZM16,120h9.38V98.69a4.69,4.69,0,0,0-9.38,0Z"/>
        <path className="cls-2" d="M80.92,124H67.53a2,2,0,0,1-2-2V98.69a8.69,8.69,0,0,1,17.38,0V122A2,2,0,0,1,80.92,124Zm-11.38-4h9.38V98.69a4.69,4.69,0,0,0-9.38,0Z"/>
        <path className="cls-1" d="M47.08,92a10.67,10.67,0,0,0-10.57,10.77V122H57.65V102.77A10.67,10.67,0,0,0,47.08,92Z"/>
        <path className="cls-3" d="M92,124H2a2,2,0,0,1-2-2V18.36a2,2,0,0,1,2-2H92a2,2,0,0,1,2,2V122A2,2,0,0,1,92,124ZM4,120H90V20.36H4Z"/>
        <path className="cls-3" d="M77.31,20.36H16.69a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2H77.31a2,2,0,0,1,2,2V18.36A2,2,0,0,1,77.31,20.36Zm-58.61-4H75.31V4H18.69Z"/>
        <path className="cls-0" d="M57.65,124H36.52a2,2,0,0,1-2-2V102.77a12.57,12.57,0,1,1,25.13,0V122A2,2,0,0,1,57.65,124Zm-19.13-4H55.65V102.77a8.57,8.57,0,1,0-17.13,0Z"/>
        <path className="cls-2" d="M54.15,60.51a2,2,0,0,1-2-2V51.6a2,2,0,0,1,4,0v6.91A2,2,0,0,1,54.15,60.51Z"/>
        <path className="cls-2" d="M40.77,60.51a2,2,0,0,1-2-2V51.6a2,2,0,0,1,4,0v6.91A2,2,0,0,1,40.77,60.51Z"/>
        <path className="cls-2" d="M54.15,40.34a2,2,0,0,1-2-2V32a2,2,0,0,1,4,0v6.34A2,2,0,0,1,54.15,40.34Z"/>
        <path className="cls-2" d="M40.77,40.34a2,2,0,0,1-2-2V32a2,2,0,1,1,4,0v6.34A2,2,0,0,1,40.77,40.34Z"/>
        <path className="cls-2" d="M80.92,60.51a2,2,0,0,1-2-2V51.6a2,2,0,0,1,4,0v6.91A2,2,0,0,1,80.92,60.51Z"/>
        <path className="cls-2" d="M67.53,60.51a2,2,0,0,1-2-2V51.6a2,2,0,0,1,4,0v6.91A2,2,0,0,1,67.53,60.51Z"/>
        <path className="cls-2" d="M80.92,40.34a2,2,0,0,1-2-2V32a2,2,0,0,1,4,0v6.34A2,2,0,0,1,80.92,40.34Z"/>
        <path className="cls-2" d="M67.53,40.34a2,2,0,0,1-2-2V32a2,2,0,0,1,4,0v6.34A2,2,0,0,1,67.53,40.34Z"/>
        <path className="cls-2" d="M27.38,60.51a2,2,0,0,1-2-2V51.6a2,2,0,0,1,4,0v6.91A2,2,0,0,1,27.38,60.51Z"/>
        <path className="cls-2" d="M14,60.51a2,2,0,0,1-2-2V51.6a2,2,0,0,1,4,0v6.91A2,2,0,0,1,14,60.51Z"/>
        <path className="cls-2" d="M54.15,81a2,2,0,0,1-2-2V72.09a2,2,0,0,1,4,0V79A2,2,0,0,1,54.15,81Z"/>
        <path className="cls-2" d="M40.77,81a2,2,0,0,1-2-2V72.09a2,2,0,0,1,4,0V79A2,2,0,0,1,40.77,81Z"/>
        <path className="cls-2" d="M80.92,81a2,2,0,0,1-2-2V72.09a2,2,0,0,1,4,0V79A2,2,0,0,1,80.92,81Z"/>
        <path className="cls-2" d="M67.53,81a2,2,0,0,1-2-2V72.09a2,2,0,0,1,4,0V79A2,2,0,0,1,67.53,81Z"/>
        <path className="cls-2" d="M27.38,81a2,2,0,0,1-2-2V72.09a2,2,0,0,1,4,0V79A2,2,0,0,1,27.38,81Z"/>
        <path className="cls-2" d="M14,81a2,2,0,0,1-2-2V72.09a2,2,0,0,1,4,0V79A2,2,0,0,1,14,81Z"/>
        <path className="cls-2" d="M27.38,40.34a2,2,0,0,1-2-2V32a2,2,0,0,1,4,0v6.34A2,2,0,0,1,27.38,40.34Z"/>
        <path className="cls-2" d="M14,40.34a2,2,0,0,1-2-2V32a2,2,0,0,1,4,0v6.34A2,2,0,0,1,14,40.34Z"/>
        <path className="cls-4" d="M64.45,12.18H29.55a2,2,0,1,1,0-4h34.9a2,2,0,1,1,0,4Z"/>
    </svg>
)

const TinyHouseSvg = ({className}) => (
    <svg version="1.1" className={className} viewBox="0 0 508.54 508.54">
        <g>
            <path className="cls-3" d="M506.296,53.805l-18.27-48.929c-0.696-1.863-2.104-3.374-3.913-4.2c-1.812-0.826-3.873-0.899-5.737-0.203L6.647,176.615   c-3.881,1.449-5.852,5.77-4.403,9.65l18.27,48.929c0.696,1.863,2.104,3.374,3.913,4.2c0.987,0.45,2.049,0.677,3.113,0.677   c0.888,0,1.776-0.158,2.624-0.474l31.84-11.889v189.676c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5V222.107l372.689-139.16   v73.182c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5V77.346l37.2-13.89C505.774,62.006,507.745,57.686,506.296,53.805z    M31.942,222.92l-13.022-34.876L476.598,17.15l13.022,34.876L31.942,222.92z"/>
            <path className="cls-1" d="M457.193,178.63c-4.143,0-7.5,3.358-7.5,7.5v307.41h-27.594V279.277c0-4.142-3.357-7.5-7.5-7.5H291.45   c-4.143,0-7.5,3.358-7.5,7.5V493.54H77.003v-46.155c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v53.655   c0,4.142,3.357,7.5,7.5,7.5h387.689c4.143,0,7.5-3.358,7.5-7.5V186.13C464.693,181.988,461.335,178.63,457.193,178.63z    M298.95,493.54V286.777h108.149V493.54H298.95z"/>
            <path className="cls-2" d="M262.308,435.766c4.143,0,7.5-3.358,7.5-7.5V279.277c0-4.142-3.357-7.5-7.5-7.5H103.546c-4.143,0-7.5,3.358-7.5,7.5   v148.988c0,4.142,3.357,7.5,7.5,7.5H262.308z M111.046,420.766v-16.931h143.762v16.931H111.046z M254.808,388.835h-64.381V286.777   h64.381V388.835z M175.427,286.777v102.058h-64.381V286.777H175.427z"/>
            <path className="cls-2" d="M291.45,254.99h123.149c4.143,0,7.5-3.358,7.5-7.5v-77.678c0-4.142-3.357-7.5-7.5-7.5H291.45c-4.143,0-7.5,3.358-7.5,7.5   v77.678C283.95,251.632,287.307,254.99,291.45,254.99z M407.099,239.99h-46.575v-62.678h46.575V239.99z M298.95,177.312h46.574   v62.678H298.95V177.312z"/>
            <path className="cls-3" d="M342.273,392.972h-27.366c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h27.366c4.143,0,7.5-3.358,7.5-7.5   S346.416,392.972,342.273,392.972z"/>
        </g>
    </svg>
)

const TreeHouse = ({className}) => (
    <svg version="1.1" className={className} viewBox="0 0 505 505">
        <g>
            <path className="cls-2" d="M497.5,302.091H464.98V99.233H497.5c2.475,0,4.79-1.221,6.188-3.262c1.398-2.042,1.7-4.642,0.806-6.949L471.848,4.79   C470.729,1.903,467.951,0,464.855,0H40.146c-3.097,0-5.874,1.903-6.993,4.79L0.507,89.022c-0.895,2.307-0.593,4.907,0.806,6.949   c1.397,2.041,3.713,3.262,6.188,3.262h28.073v202.858H7.5c-4.143,0-7.5,3.358-7.5,7.5v56.688c0,4.142,3.357,7.5,7.5,7.5h19.649   l57.606,40.201c1.308,0.913,2.804,1.35,4.285,1.35c2.368,0,4.699-1.119,6.157-3.208c2.37-3.396,1.538-8.072-1.858-10.442   l-39.979-27.9h43.69l93.217,65.052v30.489l-72.239-50.413c-3.397-2.371-8.071-1.538-10.442,1.858   c-2.37,3.396-1.538,8.072,1.858,10.442l80.823,56.403v9.889c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5V373.779h94.466V497.5   c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-9.888l163.119-113.833H497.5c4.143,0,7.5-3.358,7.5-7.5v-56.688   C505,305.449,501.643,302.091,497.5,302.091z M50.573,99.233h322.852c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5H18.45   L45.282,15h414.436l26.832,69.232h-83.123c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h46.553v202.858H229.107V145.392   c0-4.142-3.357-7.5-7.5-7.5H99.11c-4.143,0-7.5,3.358-7.5,7.5v156.699H50.573V99.233z M214.107,302.091H106.61V152.892h107.497   V302.091z M190.267,420.54l-67.007-46.761h67.007V420.54z M381.74,373.779l-67.008,46.761v-46.761H381.74z M314.733,469.32v-30.489   l93.218-65.052h43.69L314.733,469.32z M490,358.779H15v-41.688h475V358.779z"/>
            <path className="cls-3" d="M278.945,275.39h122.498c4.143,0,7.5-3.358,7.5-7.5V145.392c0-4.142-3.357-7.5-7.5-7.5H278.945c-4.143,0-7.5,3.358-7.5,7.5   V267.89C271.445,272.032,274.803,275.39,278.945,275.39z M286.445,260.39v-72.312h107.498v72.312H286.445z M393.944,152.892v20.186   H286.445v-20.186H393.944z"/>
        </g>
    </svg>

)

const ExternalLinkSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" /></svg>)
const CalendarSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>)
const PhoneSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" /></svg>)
const MailSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /></svg>)
const GlobeSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" /></svg>)
const PhotgrapherSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>)
const BellSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" /></svg>)

export enum Icons {
    Island,
    Montain,
    Hotel,
    TinyHouse,
    TreeHouse,
    ExternalLinkSvg,
    CalendarSvg,
    PhoneSvg,
    MailSvg,
    GlobeSvg,
    PhotgrapherSvg,
    BellSvg
}

const SvgIcons: React.FC<{icon: Icons, className?: string }> = ({icon, className}) => {
    switch (icon) {
        case Icons.Island: return <IslandSvg className={className} />
        case Icons.Montain: return <MontainSvg className={className} />
        case Icons.Hotel: return <HotelSvg className={className} />
        case Icons.TinyHouse: return <TinyHouseSvg className={className} />
        case Icons.TreeHouse: return <TreeHouse className={className} />
        case Icons.ExternalLinkSvg: return <ExternalLinkSvg/>
        case Icons.CalendarSvg: return <CalendarSvg/>
        case Icons.PhoneSvg: return <PhoneSvg/>
        case Icons.MailSvg: return <MailSvg/>
        case Icons.GlobeSvg: return <GlobeSvg/>
        case Icons.PhotgrapherSvg: return <PhotgrapherSvg/>
        case Icons.BellSvg: return <BellSvg />
        default: return null
    }
}

export {SvgIcons}